import { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import Autocomplete from "@mui/material/Autocomplete";
import MDDatePicker from "components/MDDatePicker";
import MDAlert from "components/MDAlert";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";
import { Checkbox } from "@mui/material";
import Switch from "@mui/material/Switch";
import { Divider } from "@mui/material";
import TextField from "@mui/material/TextField";
import default_data from "values/default";

import AuthService from "services/auth.service";
import { updateAgentDetails } from "store/userSlice";

function User() {
  const formRef = useRef(null);
  const dispatch = useDispatch();

  const agentFromRedux = useSelector((state) => state.data.agent);
  const [userDetails, setDetails] = useState(agentFromRedux);
  const [changes, setChanges] = useState({});
  const influencerType = agentFromRedux.influencer;
  const [accountOnPause, setIsChecked] = useState(agentFromRedux.account_pause);
  const [errors, setErrors] = useState({});
  const [alert, setAlert] = useState({ visible: false, message: "" });

  const [expertise, setExpertiseItems] = useState(agentFromRedux.expertise ? agentFromRedux.expertise.split(';') : "");
  const [pa, setPAItems] = useState(agentFromRedux.associations ? agentFromRedux.associations.split(';') : "");
  const [ct, setCTItems] = useState(agentFromRedux.certifications && agentFromRedux.certifications.length > 0 ? agentFromRedux.certifications.split(';') : []);
  const [languages, setLanguageItems] = useState(agentFromRedux.language && agentFromRedux.language.length > 0 ? agentFromRedux.language.split(';') : []);
  const [loans, setLoanItems] = useState(agentFromRedux.loan && agentFromRedux.loan.length > 0 ? agentFromRedux.loan.split(';') : []);

  const data = default_data.expertise;
  const associations = default_data.associations;
  const certs = default_data.certifications;
  const language = default_data.languages;
  const loan = default_data.loans;

  useEffect(() => {
    setDetails(agentFromRedux);
  }, [agentFromRedux]);

  function handleExpertiseChange(event, index) {
    const checked = event.target.checked;
    setExpertiseItems(prevState => {
      const updatedArray = checked ? [...prevState, index] : prevState.filter(item => item !== index);
      setChanges(prevChanges => ({ ...prevChanges, expertise: updatedArray.join(';') }));
      return updatedArray;
    });
  }

  function handleProfessionalChange(event, index) {
    const checked = event.target.checked;
    setPAItems(prevState => {
      const updatedArray = checked ? [...prevState, index] : prevState.filter(item => item !== index);
      setChanges(prevChanges => ({ ...prevChanges, associations: updatedArray.join(';') }));
      return updatedArray;
    });
  }

  function handleCertificationsChange(event, index) {
    const checked = event.target.checked;
    setCTItems(prevState => {
      const updatedArray = checked ? [...prevState, index] : prevState.filter(item => item !== index);
      setChanges(prevChanges => ({ ...prevChanges, certifications: updatedArray.join(';') }));
      return updatedArray;
    });
  }

  function handleLanguagesChange(event, index) {
    const checked = event.target.checked;
    setLanguageItems(prevState => {
      const updatedArray = checked ? [...prevState, index] : prevState.filter(item => item !== index);
      setChanges(prevChanges => ({ ...prevChanges, language: updatedArray.join(';') }));
      return updatedArray;
    });
  }

  function handleLoansChange(event, index) {
    const checked = event.target.checked;
    setLoanItems(prevState => {
      const updatedArray = checked ? [...prevState, index] : prevState.filter(item => item !== index);
      setChanges(prevChanges => ({ ...prevChanges, loan: updatedArray.join(';') }));
      return updatedArray;
    });
  }

  function handleCheckboxChange(event) {
    setIsChecked(event.target.checked);
    setChanges(prevChanges => ({ ...prevChanges, account_pause: event.target.checked }));
  }

  const onChangeState = (state_name) => {
    const stateObj = default_data.states.find(state => state.name === state_name);
    const newState = stateObj ? stateObj.name : '';
    setDetails(prevDetails => ({
      ...prevDetails,
      state: newState
    }));
    setChanges(prevChanges => ({ ...prevChanges, state: newState }));
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    const formattedValue = formatPhoneNumber(name, value);
    setDetails(prevDetails => ({
      ...prevDetails,
      [name]: formattedValue
    }));
    setChanges(prevChanges => ({ ...prevChanges, [name]: formattedValue }));
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: false
    }));
  }

  const formatPhoneNumber = (name, value) => {
    if (name === 'mobile' || name === 'main_phone' || name === 'coordinator_phone' || name === 'office_assistant_phone') {
      const formattedPhoneNumber = value.replace(/\D/g, '')
        .replace(/^(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3');
      return formattedPhoneNumber;
    }
    if (name === 'price_min_buyers' || name === 'price_min_sellers' || name === 'price_min_buyers_sellers') {
      return Number(value.replace(/[^0-9]/g, '')).toLocaleString();
    }
    return value;
  }

  const cleanNumbersforPrices = (number) => {
    return number.replace(/[^0-9]/g, '');
  }

  const validateForm = () => {
    const newErrors = {};
    if (!userDetails.firstname) newErrors.firstname = true;
    if (!userDetails.lastname) newErrors.lastname = true;
    if (!userDetails.street) newErrors.street = true;
    if (!userDetails.city) newErrors.city = true;
    if (!userDetails.state) newErrors.state = true;
    if (!userDetails.postalcode) newErrors.postalcode = true;
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const onClickUpdate = () => {

    if (!validateForm()) {
      setAlert({ visible: true, message: "Please fill out all required fields." });
      return;
    }

    let updatedDetails = { ...userDetails, ...changes };

    if (updatedDetails.state.length > 2) {
      const stateObj = default_data.states.find(state => state.name === updatedDetails.state);
      updatedDetails.state = stateObj ? stateObj.abbr : updatedDetails.state;
    }

    const referralTypes = [
      { value: 'Home Buyers', text: 'Buyers' },
      { value: 'Investors', text: 'Investors' },
      { value: 'Home Sellers', text: 'Sellers' },
      { value: 'Short Sales', text: 'Short Sales' },
      { value: 'Commercial Properties', text: 'Commercial Properties' },
      { value: 'Land', text: 'Land' },
    ];
    
    const filteredReferralTypes = referralTypes.filter(type => updatedDetails.referralTypes.includes(type.text));
    const valueArray = filteredReferralTypes.map(type => type.value);
    updatedDetails.referralTypes = valueArray;
    updatedDetails.expertise = expertise.join(";");
    updatedDetails.phone = updatedDetails.mobile;
    updatedDetails.price_min_buyers = cleanNumbersforPrices(updatedDetails.price_min_buyers);
    updatedDetails.price_min_sellers = cleanNumbersforPrices(updatedDetails.price_min_sellers);
    updatedDetails.price_min_buyers_sellers = cleanNumbersforPrices(updatedDetails.price_min_buyers_sellers);
    updatedDetails.account_pause = accountOnPause;

    const payload = {};
    for (let key in changes) {
      if (changes.hasOwnProperty(key)) {
        payload[key] = updatedDetails[key];
      }
    }

    AuthService.updateCompany(payload)
      .then(() => {
        dispatch(updateAgentDetails(payload));
        window.location.reload();
      });
  }

  const handleDate = (e) => {
    const date = new Date(e);
    const formattedDate = formatDate(date);
    setDetails(prevDetails => ({
      ...prevDetails,
      account_no_longer_hold_date: formattedDate
    }));
    setChanges(prevChanges => ({ ...prevChanges, account_no_longer_hold_date: formattedDate }));
  }

  const handleDate_agent = (e) => {
    const date = new Date(e);
    const formattedDate = formatDate(date);
    setDetails(prevDetails => ({
      ...prevDetails,
      agent_license_date: formattedDate
    }));
    setChanges(prevChanges => ({ ...prevChanges, agent_license_date: formattedDate }));
  }

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  return (
    <MDBox ref={formRef} mt={6} mb={3}>
      <Grid container spacing={3} mb={4} justifyContent="center">
        <Grid item xs={12} lg={8}>
          {alert.visible && (
            <MDAlert color="error" dismissible onClose={() => setAlert({ visible: false, message: "" })}>
              {alert.message}
            </MDAlert>
          )}
          <Card>
            <MDBox p={2}>
              <MDTypography align="left" variant="h3">Your Information</MDTypography>
            </MDBox>
            <MDBox pt={2} px={2} pb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                  <MDInput
                    name="firstname"
                    fullWidth
                    label="First Name * "
                    inputProps={{ type: "text", autoComplete: "" }}
                    value={userDetails.firstname}
                    onChange={handleChange}
                    error={errors.firstname}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <MDInput
                    fullWidth
                    label="Last Name * "
                    inputProps={{ type: "text", autoComplete: "" }}
                    name="lastname"
                    value={userDetails.lastname}
                    onChange={handleChange}
                    error={errors.lastname}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <MDInput
                    fullWidth
                    label="Agent Team Name"
                    inputProps={{ type: "text", autoComplete: "" }}
                    name="agent_team_name"
                    value={userDetails.agent_team_name}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <MDInput
                    fullWidth
                    label="Email Address (Your Login) *"
                    inputProps={{ type: "text", autoComplete: "" }}
                    name="email"
                    value={userDetails.email}
                    disabled={true}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <MDInput
                    fullWidth
                    label="Agent License Number"
                    inputProps={{ type: "text", autoComplete: "" }}
                    name="agent_license"
                    value={userDetails.agent_license}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <MDTypography variant="overline" pr={2}>Agent License Exp</MDTypography>
                  <MDDatePicker name='agent_license_date' value={userDetails.agent_license_date} dateFormat="dd/MM/yyyy" onChange={handleDate_agent} />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <MDInput
                    fullWidth
                    label="Alternate Email Address"
                    inputProps={{ type: "text", autoComplete: "" }}
                    name="other_email"
                    value={userDetails.other_email}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <MDInput
                    fullWidth
                    label="Mobile Number"
                    inputProps={{ type: "text", autoComplete: "" }}
                    name="mobile"
                    value={userDetails.mobile}
                    onChange={handleChange}
                  />
                </Grid>
                {influencerType === "cost_per_lead" ? <></> :
                  <Grid item xs={12} lg={6}>
                    <MDInput
                      fullWidth
                      label="License Type"
                      inputProps={{ type: "text", autoComplete: "" }}
                      name="agent_type"
                      value={userDetails.agent_type}
                      onChange={handleChange}
                    />
                  </Grid>}
                <Grid item xs={12} lg={6}>
                  <MDInput
                    fullWidth
                    label="Intro Video URL"
                    inputProps={{ type: "text", autoComplete: "" }}
                    name="agent_intro_url"
                    value={userDetails.agent_intro_url}
                    onChange={handleChange}
                  />
                </Grid>
                {influencerType === "cost_per_lead" ? <></> :
                  <Grid item xs={12} lg={6}>
                    <MDInput
                      fullWidth
                      label="Current CRM"
                      inputProps={{ type: "text", autoComplete: "" }}
                      name="current_crm"
                      value={userDetails.current_crm}
                      onChange={handleChange}
                    />
                  </Grid>}
                {influencerType === "cost_per_lead" ? <></> :
                  <Grid item xs={12} lg={6}>
                    <MDInput
                      fullWidth
                      label="CRM Email Parsing Address"
                      inputProps={{ type: "text", autoComplete: "" }}
                      name="crm_email"
                      value={userDetails.crm_email}
                      onChange={handleChange}
                    />
                  </Grid>}
                {influencerType === "cost_per_lead" ? <></> :
                  <Grid item xs={12} lg={6}>
                    <Checkbox checked={accountOnPause} onChange={handleCheckboxChange} />
                    <MDTypography variant="overline" pr={2}>Account on pause</MDTypography>
                  </Grid>}
                {influencerType === "cost_per_lead" ? <></> :
                  <Grid item xs={12} lg={6}>
                    <MDTypography variant="overline" pr={2}>Account Release Date</MDTypography>
                    <MDDatePicker name='account_no_longer_hold_date' value={userDetails.account_no_longer_hold_date} dateFormat="dd/MM/yyyy" onChange={handleDate} />
                  </Grid>}
              </Grid>
            </MDBox>
          </Card>
          <MDBox align="right" mt={4} mb={1}>
            <MDButton variant="gradient" color="solidPrimary" sx={{ fontSize: "0.875rem" }} onClick={onClickUpdate}>
              Update Your Details
            </MDButton>
          </MDBox>
        </Grid>

        <Grid item xs={12} lg={8}>
          <Card>
            <MDBox p={2}>
              <MDTypography variant="h5">Address</MDTypography>
              <MDTypography variant="caption"> * Please ensure this address reflects the local areas you work within a 50-mile radius.</MDTypography>
              <Grid container spacing={3} pt={3}>
                <Grid item xs={12} lg={6}>
                  <MDInput
                    fullWidth
                    label="Street Address * "
                    inputProps={{ type: "text", autoComplete: "" }}
                    name="street"
                    value={userDetails.street}
                    onChange={handleChange}
                    error={errors.street}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <MDInput
                    fullWidth
                    label="City * "
                    inputProps={{ type: "text", autoComplete: "" }}
                    name="city"
                    value={userDetails.city}
                    onChange={handleChange}
                    error={errors.city}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Autocomplete
                    onChange={(event, value) => onChangeState(value)}
                    value={userDetails.state}
                    options={default_data.state_names}
                    renderInput={(params) => (
                      <FormField {...params} label="State*" InputLabelProps={{ shrink: true }} />
                    )}
                    error={errors.state}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <MDInput
                    fullWidth
                    label="Zip Code *"
                    inputProps={{ type: "text", autoComplete: "" }}
                    name="postalcode"
                    value={userDetails.postalcode}
                    onChange={handleChange}
                    error={errors.postalcode}
                  />
                </Grid>
              </Grid>
            </MDBox>
          </Card>
          <MDBox align="right" mt={4} mb={1}>
            <MDButton variant="gradient" color="solidPrimary" sx={{ fontSize: "0.875rem" }} onClick={onClickUpdate}>
              Update Your Address
            </MDButton>
          </MDBox>
        </Grid>
        {influencerType === "cost_per_lead" ? <></> :
          <Grid item xs={12} lg={8}>
            <Card>
              <MDBox p={2}>
                <MDTypography align="left" variant="h3">Public Information</MDTypography>
              </MDBox>
              <MDBox pt={2} px={2}>
                <Grid container spacing={3} pb={3}>
                  <Grid item xs={12} lg={6}>
                    <MDInput
                      fullWidth
                      label="Price Minimum for Buyers"
                      inputProps={{ type: "text", autoComplete: "" }}
                      name="price_min_buyers"
                      value={userDetails.price_min_buyers}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <MDInput
                      fullWidth
                      label="Price Minimum for Sellers"
                      inputProps={{ type: "email", autoComplete: "" }}
                      name="price_min_sellers"
                      value={userDetails.price_min_sellers}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <MDInput
                      fullWidth
                      label="Price Min. for Land Buyers/Sellers"
                      inputProps={{ type: "text", autoComplete: "" }}
                      name="price_min_buyers_sellers"
                      value={userDetails.price_min_buyers_sellers}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <MDInput
                      fullWidth
                      label="Years In RealEstate"
                      inputProps={{ type: "text", autoComplete: "" }}
                      name="experience_years"
                      value={userDetails.experience_years}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <MDBox align="right" mt={4} mb={1}>
                      <MDButton variant="gradient" color="solidPrimary" sx={{ fontSize: "0.875rem" }} onClick={onClickUpdate}>
                        Update Public info
                      </MDButton>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <Divider orientation="horizontal" sx={{ ml: -2, mr: 1 }} />
                    <MDBox pt={1}>
                      <MDTypography align="left" variant="h3">Referral Types you would like to receive</MDTypography>
                    </MDBox>
                  </Grid>
                  {data.map((item, index) => (
                    <Grid item xs={12} lg={4}>
                      <div key={index}>
                        <Switch
                          checked={expertise.includes(item)}
                          onChange={event => handleExpertiseChange(event, item)}
                        />
                        <MDTypography variant="overline" pr={2}>{item}</MDTypography>
                      </div>
                    </Grid>
                  ))}
                  <Grid item xs={12} lg={12}>
                    <Divider orientation="horizontal" sx={{ ml: -2, mr: 1 }} />
                    <MDBox pt={1}>
                      <MDTypography align="left" variant="h3">Professional Associations</MDTypography>
                    </MDBox>
                  </Grid>
                  {associations.map((item, index) => (
                    <Grid item xs={4} lg={4}>
                      <div key={index}>
                        <Switch
                          checked={pa.includes(item)}
                          onChange={event => handleProfessionalChange(event, item)}
                        />
                        <MDTypography variant="overline" pr={2}>{item}</MDTypography>
                      </div>
                    </Grid>
                  ))}
                  <Grid item xs={12} lg={12}>
                    <MDInput
                      fullWidth
                      label="Other Professional Associations"
                      inputProps={{ type: "text", autoComplete: "" }}
                      name="other_associations"
                      value={userDetails.other_associations}
                      onChange={handleChange}
                    />
                    <MDBox align="right" mt={4} mb={1}>
                      <MDButton variant="gradient" color="solidPrimary" sx={{ fontSize: "0.875rem" }} onClick={onClickUpdate}>
                        Update Details
                      </MDButton>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <Divider orientation="horizontal" sx={{ ml: -2, mr: 1 }} />
                    <MDBox pt={1}>
                      <MDTypography align="left" variant="h3">Training and Certifications</MDTypography>
                    </MDBox>
                  </Grid>
                  {certs.map((item, index) => (
                    <Grid item xs={6} lg={4}>
                      <div key={index}>
                        <Switch
                          checked={ct.includes(item)}
                          onChange={event => handleCertificationsChange(event, item)}
                        />
                        <MDTypography variant="overline" pr={2}>{item}</MDTypography>
                      </div>
                    </Grid>
                  ))}
                  <Grid item xs={12} lg={12}>
                    <MDInput
                      fullWidth
                      label="Other Training and Certifications"
                      inputProps={{ type: "text", autoComplete: "" }}
                      name="other_certifications"
                      value={userDetails.other_certifications}
                      onChange={handleChange}
                    />
                    <MDBox align="right" mt={4} mb={1}>
                      <MDButton variant="gradient" color="solidPrimary" sx={{ fontSize: "0.875rem" }} onClick={onClickUpdate}>
                        Update Details
                      </MDButton>
                    </MDBox>
                  </Grid>

                  <Grid item xs={12} lg={12}>
                    <Divider orientation="horizontal" sx={{ ml: -2, mr: 1 }} />
                    <MDBox pt={1}>
                      <MDTypography align="left" variant="h3">Languages Spoken</MDTypography>
                    </MDBox>
                  </Grid>
                  {language.map((item, index) => (
                    <Grid item xs={12} lg={4}>
                      <div key={index}>
                        <Switch
                          checked={languages.includes(item)}
                          onChange={event => handleLanguagesChange(event, item)}
                        />
                        <MDTypography variant="overline" pr={2}>{item}</MDTypography>
                      </div>
                    </Grid>
                  ))}
                  <Grid item xs={12} lg={12}>
                    <Divider orientation="horizontal" sx={{ ml: -2, mr: 1 }} />
                    <MDBox pt={1}>
                      <MDTypography align="left" variant="h3">Types of Loan You Specialize in</MDTypography>
                    </MDBox>
                  </Grid>
                  {loan.map((item, index) => (
                    <Grid item xs={12} lg={4}>
                      <div key={index}>
                        <Switch
                          checked={loans.includes(item)}
                          onChange={event => handleLoansChange(event, item)}
                        />
                        <MDTypography variant="overline" pr={2}>{item}</MDTypography>
                      </div>
                    </Grid>
                  ))}
                  <Grid item xs={12} lg={12}>
                    <Divider orientation="horizontal" sx={{ ml: -2, mr: 1 }} />
                    <MDBox>
                      <MDTypography align="left" variant="h3">Areas of Service *</MDTypography>
                      <MDTypography variant="caption">Enter the service areas you wish to receive referrals from - use cities and counties and not zip codes</MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <TextField
                      placeholder="Add Details here (COUNTIES & CITIES only)"
                      size="large"
                      multiline
                      rows={4}
                      sx={{ padding: "20px", width: "100%" }}
                      name='service_area'
                      value={userDetails.service_area}
                      onChange={handleChange}
                    />
                    <MDBox px={3} align="right">
                      <MDTypography variant="h6" color="text">{1000 - (userDetails.service_area ? userDetails.service_area.length : 0)} Characters Remaining</MDTypography>
                    </MDBox>
                    <MDBox align="right" mt={4} mb={1}>
                      <MDButton variant="gradient" color="solidPrimary" sx={{ fontSize: "0.875rem" }} onClick={onClickUpdate}>
                        Update Areas of Service
                      </MDButton>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <Divider orientation="horizontal" sx={{ ml: -2, mr: 1 }} />
                    <MDBox>
                      <MDTypography align="left" variant="h3">About me</MDTypography>
                      <MDTypography variant="caption">Add professional details and personal achievements you would like to highlight for consumers </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <TextField
                      placeholder="Add Details here"
                      size="large"
                      multiline
                      rows={4}
                      sx={{ padding: "20px", width: "100%" }}
                      name='description'
                      value={userDetails.description}
                      onChange={handleChange}
                    />
                    <MDBox px={3} align="right">
                      <MDTypography variant="h6" color="text">{2000 - (userDetails.description ? userDetails.description.length : 0)} Characters Remaining</MDTypography>
                    </MDBox>
                    <MDBox align="right" mt={4} mb={1}>
                      <MDButton variant="gradient" color="solidPrimary" sx={{ fontSize: "0.875rem" }} onClick={onClickUpdate}>
                        Update About Me
                      </MDButton>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>}
        {influencerType === "cost_per_lead" ? <></> :
          <Grid item xs={12} lg={8}>
            <Card>
              <MDBox p={2}>
                <MDTypography align="left" variant="h3">Social Info</MDTypography>
              </MDBox>
              <MDBox pt={2} px={2}>
                <Grid container spacing={3} pb={3}>
                  <Grid item xs={12} lg={6}>
                    <MDInput
                      fullWidth
                      label="Website URL"
                      inputProps={{ type: "text", autoComplete: "" }}
                      name="website"
                      value={userDetails.website}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <MDInput
                      fullWidth
                      label="Youtube URL"
                      inputProps={{ type: "text", autoComplete: "" }}
                      name="youtube"
                      value={userDetails.youtube}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <MDInput
                      fullWidth
                      label="Facebook URL"
                      inputProps={{ type: "text", autoComplete: "" }}
                      name="facebook_url"
                      value={userDetails.facebook_url}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <MDInput
                      fullWidth
                      label="Twitter URL"
                      inputProps={{ type: "text", autoComplete: "" }}
                      name="twitter_url"
                      value={userDetails.twitter_url}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <MDInput
                      fullWidth
                      label="LinkedIn URL"
                      inputProps={{ type: "text", autoComplete: "" }}
                      name="linkedin_url"
                      value={userDetails.linkedin_url}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <MDInput
                      fullWidth
                      label="Instagram Handle"
                      inputProps={{ type: "text", autoComplete: "" }}
                      name="instagram"
                      value={userDetails.instagram}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <MDInput
                      fullWidth
                      label="Zillow Screen Name"
                      inputProps={{ type: "text", autoComplete: "" }}
                      name="zillow"
                      value={userDetails.zillow}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
            <MDBox align="right" mt={4} mb={1}>
              <MDButton variant="gradient" color="solidPrimary" sx={{ fontSize: "0.875rem" }} onClick={onClickUpdate}>
                Update Social Details
              </MDButton>
            </MDBox>
          </Grid>}
      </Grid>
    </MDBox>
  );
}

export default User;
