// Material Dashboard 2 PRO React layouts
import ProfileOverview from "layouts/pages/profile/profile-overview";
import SignInBasic from "layouts/authentication/sign-in/basic";
import PlanPage from "layouts/pages/plan";

// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

// Images
import profilePicture from "assets/images/team-3.jpg";

const routes = [
    {
        name: "user_data",
        type: "collapse",
        key: "user_data",
        icon: <MDAvatar src={profilePicture} alt="profile Image" size="sm" />,
        collapse: [
            {
                name: "Edit Profile",
                key: "profile",
                route: "/profile",
                component: <ProfileOverview />,
            },
            {
                name: "My Plan",
                key: "plan",
                route: "/my-plan",
                component: <PlanPage />,
            },
            {
                name: "Logout",
                key: "logout",
                route: "",
                component: <SignInBasic />,
            },
        ],
    },
    { type: "divider", key: "divider-0" },
    {
        type: "collapse",
        name: "Dashboard",
        key: "dashboard",
        icon: <Icon fontSize="medium">home</Icon>,
        route:"/dashboard",
        noCollapse: true,
    },
    { type: "title", title: "My Referrals", key: "my-referrals" },
    {
        type: "collapse",
        name: "Active",
        key: "active",
        icon: <Icon fontSize="medium">active</Icon>,
        collapse: [
            { name: "Pending", key: "pending", route: "/referral/active/pending"},
            { name: "Overdue", key: "overdue", route: "/referral/active/overdue"},
            { name: "All Active", key: "allactive", route: "/referral/active/allactive"},
            { name: "Prospects", key: "prospects", route: "/referral/active/prospects" },
            { name: "Clients", key: "clients", route: "/referral/active/clients" },
            { name: "Under Contract", key: "undercontract", route: "/referral/active/undercontract" },
            { name: "Nurture", key: "nurture", route: "/referral/active/nurture" },
        ],
    },
    {
        type: "collapse",
        name: "Not Active",
        key: "notactive",
        icon: <Icon fontSize="medium">not-active</Icon>,
        collapse: [
            { name: "Closed", key: "closed", route: "/referral/not-active/closed" },
            { name: "Cancelled", key: "cancelled", route: "/referral/not-active/cancelled" },
        ],
    },

    { type: "title", title: "OutBound Referrals", key: "outboundreferrals" },
    {
        type: "collapse",
        name: "Active",
        key: "outbound-active",
        icon: <Icon fontSize="medium">active</Icon>,
        collapse: [
            { name: "Prospects", key: "outbound-prospects", route: "/out-bound/active/prospects" },
            { name: "Clients", key: "outbound-clients", route: "/out-bound/active/clients" },
            { name: "Under Contract", key: "outbound-undercontract", route: "/out-bound/active/under-contract" },
            { name: "Nurture", key: "outbound-nurture", route: "/out-bound/active/nurture" },
        ],
    },
    {
        type: "collapse",
        name: "Not Active",
        key: "outbound-notactive",
        icon: <Icon fontSize="medium">not-active</Icon>,
        collapse: [
            { name: "Closed", key: "outbound-closed", route: "/out-bound/not-active/closed" },
            { name: "Cancelled", key: "outbound-cancelled", route: "/out-bound/not-active/cancelled" },
        ],
    },
    {
        type: "collapse",
        name: "Manually Add",
        key: "outbound-manuallyadd",
        icon: <Icon>add</Icon>,
        route:"/add-referral",
        noCollapse: true,
    },

    { type: "divider", key: "divider-1" },
    { type: "title", title: "Resources", key: "resources" },
    {
        type: "collapse",
        name: "Agent FAQs",
        key: "agentfaqs",
        href: "https://www.myagentfinder.com/faq",
        icon: <Icon fontSize="medium">faqs</Icon>,
        noCollapse: true,
    },     {
        type: "collapse",
        name: "Report Multiple Transaction",
        key: "duplicate",
        icon: <Icon fontSize="medium">contactus</Icon>,
        route:"/reportduplicate",
        noCollapse: true,
    },   {
        type: "collapse",
        name: "Additional Resources",
        key: "Resources",
        icon: <Icon fontSize="medium">active</Icon>,
        collapse: [
            { name: "Status Updates", key: "statusupdates", href: "https://www.loom.com/share/5d9c184eae2f46628ad3d617d094b057" },
        ],
    },    {
        type: "collapse",
        name: "Referral Terms",
        key: "referralterms",
        href: "/agree",
        icon: <Icon fontSize="medium">terms</Icon>,
        noCollapse: true,
    },    {
        type: "collapse",
        name: "W-9 Form",
        key: "w-9form",
        href: "https://drive.google.com/file/d/1a5nLBkDytlucLODcdWkcQVATCHtS_aBF/view",
        icon: <Icon fontSize="medium">w9form</Icon>,
        noCollapse: true,
    },    {
        type: "collapse",
        name: "Contact Us",
        key: "contactus",
        icon: <Icon fontSize="medium">contactus</Icon>,
        href:"/contact",
        noCollapse: true,
    },

];

export default routes;
